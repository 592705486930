.header {
  text-align: left;
  color: darkblue;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
table {
  border-spacing: 0;
  border: 1px solid #ededed;
}

thead > tr:nth-child(n) {
  background: darkgray;
  color: white;
  text-align: center;
}

tbody > tr:nth-child(odd) {
  background: gainsboro;
}
tbody > tr:nth-child(even) {
  background: ghostwhite;
}

table tr:last-child td {
  border-bottom: 0;
}
table th,
table td {
  margin: 0;
  border-bottom: 1px solid #ededed;
  border-right: 1px solid #ededed;
  position: relative;
  padding: 1em;
}
table th:last-child,
table td:last-child {
  border-right: 0;
}

table th::before {
  position: absolute;
  right: 15px;
  top: 16px;
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.App {
  display: flex;
  flex-direction: column;
  padding: 20px;
  color: #00000094;
}
